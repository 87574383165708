import React from "react"
// import { Link } from "gatsby"
import footerStyles from "./footer.module.css"

export default function Footer() {
  return(
    <div className={footerStyles.footer}>
      <div>
        <p>paulettejbrooks@gmail.com</p>
        <p>(+44) 07775 782 736</p>
      </div>
    </div>
  )
}
