import React from "react"

const PageSection = ({ post }) => (
  <div id={post.frontmatter.anchor}>
    <h2>{post.frontmatter.title}</h2>
    <div
      className={post.title}
      dangerouslySetInnerHTML={{ __html: post.html }}
    />
  </div>
)

export default PageSection
