import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import PageSection from "../components/pageSection"
import TestimonialSection from "../components/testimonialSection"

const WorkPage = ({data}) => {

  const Posts = data.pageContent.edges
    .filter(edge => !!edge.node.frontmatter.order)
    .map((edge, index) => <div key={index}><PageSection key={edge.node.id} post={edge.node} /><TestimonialSection key={data.testimonials.edges[index].node.id} post={data.testimonials.edges[index].node} /></div>)

  const PostsIndex = data.pageContent.edges
    .filter(edge => !!edge.node.frontmatter.order)
    .map(edge => <a key={edge.node.id} href={`#` + edge.node.frontmatter.anchor}>{edge.node.frontmatter.title}</a>)


  return (
    <div>
      <Header />
      <div className="content">
        <p className="posts-index">
          {PostsIndex}
        </p>
        <br />
        {Posts}
      </div>
      <Footer />
    </div>
  )
}

export default WorkPage

export const pageQuery = graphql`
  query {
    pageContent: allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "\/work/"} },
      sort: { order: DESC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          excerpt
          html
          frontmatter {
            title
            order
            anchor
          }
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "\/testimonials/"} },
      sort: { order: DESC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          excerpt
          html
          frontmatter {
            title
            order
            anchor
          }
        }
      }
    }
  }
`
