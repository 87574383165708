import React from "react"

const TestimonialSection = ({ post }) => (
  <div className="testimonial">
    <div
      className={post.title}
      dangerouslySetInnerHTML={{ __html: post.html }}
    />
    <p>{post.frontmatter.title}</p>
  </div>
)

export default TestimonialSection
